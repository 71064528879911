import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
import { notFound } from 'next/navigation';

const publicRoutes = [
  '/claims/create',
  '/register',
  '/login',
  '/reset-password',
  '/forgot-password',
];

const HttpCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

const resolvedBaseUrl =
  process.env.NEXT_RUNTIME === 'nodejs'
    ? process.env.NEXT_PUBLIC_API_URL
    : window.location.host.split('.')[0] !== 'insurer'
    ? `/api`
    : process.env.NEXT_PUBLIC_API_URL;

console.log('resolvedBaseUrl', resolvedBaseUrl);

export const httpClient = wretch(resolvedBaseUrl)
  .middlewares([
    (next) => (url, opts) => {
      try {
        return next(url, opts).then(async (res) => {
          if (
            res.status === HttpCodes.UNAUTHORIZED &&
            typeof window !== 'undefined'
          ) {
            const response = await res.json();

            if (response.code === 'unauthorized') {
              const isPublic = publicRoutes.some((route) =>
                window.location.pathname.includes(route),
              );

              if (!isPublic) {
                window.location.href = '/login';
              }
            }
          }

          if (
            res.status === HttpCodes.FORBIDDEN &&
            typeof window === 'undefined'
          ) {
            const response = await res.json();

            if (response.code === 'invalid_role') {
              notFound();
            }
          }

          return res;
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
  ])
  .options({
    cache: 'no-cache',
    credentials: 'include',
    mode: 'cors',
  })
  .addon(QueryStringAddon);
